import { lazy, Suspense, type FC, type ReactElement } from 'react';
import { useIntl, defineMessages, FormattedMessage, FormattedNumber } from 'dibs-react-intl';

import LikeHeartIcon from 'dibs-icons/exports/legacy/LikeHeartIcon';
import { Link } from 'dibs-elements/exports/Link';

// Styles
import classnames from 'classnames';
import styles from './Heart-Styles.scss';

const HeartPulseLazy = lazy(() => import(/* webpackChunkName: "HeartPulse" */ './HeartPulse'));

const messages = defineMessages({
    label: {
        id: 'dibs-portfolio-heart.HeartIconWrapper.label',
        defaultMessage: 'Save to favorites',
    },
});

const HeartCount: FC<{ count: number }> = ({ count }) => {
    let value = <FormattedNumber value={count} />;
    if (count > 999) {
        // If count is greater than 1000, display as value in thousands up to 999.9K
        const countInThousands = Math.min(999.9, count / 1000);
        // Round to tenth decimal
        const countToTenthDecimal = Math.round(countInThousands * 10) / 10;
        // Format number value
        const formattedCount = <FormattedNumber value={countToTenthDecimal} />;
        // Format value in thousands
        value = (
            <FormattedMessage
                id="HeartIcon.HeartCount.value"
                defaultMessage="{count}K"
                values={{ count: formattedCount }}
            />
        );
    }

    return <div className={styles.heartCount}>{value}</div>;
};

type WrapperProps = {
    children: ReactElement;
    onClick: () => void;
    wrapperComponent: 'Link' | 'div';
    buttonClass: string;
};

const WrapperElement: FC<WrapperProps> = ({ buttonClass, children, onClick, wrapperComponent }) => {
    const intl = useIntl();
    return wrapperComponent === 'Link' ? (
        <Link
            className={classnames(styles.iconWrapper, buttonClass)}
            ariaLabel={intl.formatMessage(messages.label)}
            onClick={onClick}
        >
            {children}
        </Link>
    ) : (
        <div className={classnames(styles.iconWrapper, buttonClass)}>{children}</div>
    );
};

type Props = {
    className: string;
    buttonClass: string;
    isStrokeWidthHeavy: boolean;
    isAnimated: boolean;
    isFavorited: boolean;
    isFilled: boolean;
    useHoverClass: boolean;
    onClick: () => void;
    onAnimationEnd: () => void;
    wrapperComponent: 'div' | 'Link';
    hideTotalLikesCount: boolean;
    itemId: string;
    theme: 'dark' | 'light';
    heartCount: number;
    animateHeartPulse: boolean;
};

const HeartIconWrapper: FC<Props> = ({
    className: classNameDef,
    buttonClass,
    isStrokeWidthHeavy = false,
    isAnimated,
    isFavorited = false,
    isFilled,
    useHoverClass = false,
    onClick,
    onAnimationEnd,
    wrapperComponent = 'Link',
    hideTotalLikesCount = false,
    itemId,
    theme,
    heartCount,
    animateHeartPulse,
}) => {
    const className = classnames(styles.heart, classNameDef);
    const iconClasses = classnames(styles.icon, {
        [styles.isStrokeWidthHeavy]: isStrokeWidthHeavy,
        [styles.hoverClass]: useHoverClass,
        [styles.dark]: theme === 'dark',
        [styles.light]: theme === 'light',
    });

    const dataTn = isFavorited ? 'item-saved-icon' : 'item-save-icon';
    const id = `${itemId}-heart`;

    return (
        <>
            <WrapperElement
                onClick={onClick}
                wrapperComponent={wrapperComponent}
                buttonClass={buttonClass}
            >
                {/* Carefully with data-tn. it must always stay together with id. It's required for heart status detection. */}
                <div className={className} onAnimationEnd={onAnimationEnd} data-tn={dataTn} id={id}>
                    {animateHeartPulse && (
                        <Suspense fallback="">
                            <HeartPulseLazy isFilled={isFilled} />
                        </Suspense>
                    )}
                    <LikeHeartIcon
                        className={iconClasses}
                        animateHeartBeat={isAnimated}
                        isFilled={isFilled}
                        theme={theme}
                    />
                </div>
            </WrapperElement>
            {heartCount > 0 && !hideTotalLikesCount && <HeartCount count={heartCount} />}
        </>
    );
};

export default HeartIconWrapper;
