import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type HeartFilledProps = {
    className?: string;
};

const HeartFilled: FunctionComponent<HeartFilledProps> = ({ className }) => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, className)}
            data-tn={'heart-filled'}
            viewBox="0 0 250 250"
        >
            <path d="M29.3 129.8C22.8 121.3 1.6 92.1.4 72.4-.8 52.3 4.1 36.2 15 24.6 30.1 8.5 51.4 6.8 59.9 6.8c1.3 0 2.2 0 2.8.1 16.2.4 39.5 7.6 58.9 39.4l3.4 5.6 3.6-5.5c16.8-25.7 37.1-39 60.3-39.5 3 0 28.9-.9 48 20.6 7.2 8.1 15.1 22.5 12.5 45.6-1.9 16.9-16.2 39.3-24.3 50.4-11.8 16.1-88.5 108.3-100.2 122L29.3 129.8z" />
        </svg>
    );
};
export default HeartFilled;
