import { type FC } from 'react';
import classnames from 'classnames';

import HeartFilled from './HeartFilled';

import styles from './styles/LikeHeartIcon.scss';

type LikeHeartIconProps = {
    className?: string;
    isFilled?: boolean;
    animateHeartBeat?: boolean;
    theme?: 'dark' | 'light';
};

const LikeHeartIcon: FC<LikeHeartIconProps> = ({
    className,
    animateHeartBeat,
    isFilled,
    theme,
}) => {
    const iconClasses = classnames(className, styles.likeIcon, {
        [styles.showHeartBeat]: animateHeartBeat,
        [styles.isFilled]: isFilled,
        [styles.dark]: theme === 'dark',
        [styles.light]: theme === 'light',
    });
    return <HeartFilled className={iconClasses} />;
};
export default LikeHeartIcon;
